.type-fade-in {
    opacity: 0;
    animation: fadeIn 1.5s ease-in-out forwards;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  